import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  toggleOpenBooking,
  toggleShowConfirmation,
  toggleOpenForm,
} from 'redux/Actions/ui-actions';
import { toggleResetData } from 'redux/Actions/form-actions';

import styles from './confirmation.module.scss';
import Arrow from '../../../../assets/arrow-down.svg';
import Button from '../../../Button/button';
import Checked from '../../../../assets/checked.svg';

const Confirmation = (props) => {
  const { openBookingFUNC, showConfirmationFUNC, openFormFUNC, resetDataFUNC, arrivalDate } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    showConfirmationFUNC();
    openBookingFUNC();
    openFormFUNC();
    resetDataFUNC();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.confirmation}>
      <header>
        <img className={styles.confirmation__icon} src={Checked} alt="Ikona" />
      </header>
      <p className={styles.confirmation__paragraph}>{t('confirmationFirst')}</p>
      <p className={styles.confirmation__paragraph}>
        {t('confirmationSecond')}
        <span className={styles.confirmation__paragraphBold}>
          &nbsp;{t('confirmationSecondPin')}
        </span>
        &nbsp;{t('confirmationSecondAnd')}
        <span className={styles.confirmation__paragraphBold}>
          &nbsp;{t('confirmationSecondQr')}
        </span>
        &nbsp;{t('confirmationThird')}
      </p>
      <p className={styles.confirmation__paragraphBold}>
        {t('confirmationFourth')} {arrivalDate.split('-').reverse().join('-')} r.
      </p>
      <p className={styles.confirmation__paragraphBoldMargin}>{t('confirmationInfo')}</p>
      <div className={styles.confirmation__box}>
        <div className={styles.confirmation__boxes}>
          <div
            className={styles.confirmation__boxPhoto}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/parking.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -60,
            }}
          />
          <p className={styles.confirmation__paragraphBox}>
            {t('confirmationInfoFirst1/3')}
            <span className={styles.confirmation__paragraphBold}>
              &nbsp;{arrivalDate.split('-').reverse().join('-')}&nbsp;r.
            </span>
            &nbsp;
            {t('confirmationInfoFirst2/3')}
            <span className={styles.confirmation__paragraphBold}>
              &nbsp;{t('confirmationInfoFirst3/3')}
            </span>
          </p>
        </div>
        <img className={styles.confirmation__arrow} src={Arrow} alt="Strzałka" />
        <div className={styles.confirmation__boxes}>
          <div
            className={styles.confirmation__boxPhoto}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/szafka.png')`,
              backgroundSize: 'cover',
              backgroundPositionX: -2,
            }}
          />
          <p className={styles.confirmation__paragraphBoxWidth}>
            {t('confirmationInfoSecond1/4')}
            <span className={styles.confirmation__paragraphBold}>
              &nbsp;{t('confirmationInfoSecond2/4')}
            </span>
            &nbsp;{t('confirmationInfoSecond3/4')}
            <span className={styles.confirmation__paragraphBold}>
              &nbsp;{t('confirmationInfoSecond4/4')}
            </span>
          </p>
        </div>
        <img className={styles.confirmation__arrow} src={Arrow} alt="Strzałka" />
        <div className={styles.confirmation__boxes}>
          <div
            className={styles.confirmation__boxPhoto}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/keys.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -40,
            }}
          />
          <p className={styles.confirmation__paragraphBox}>
            <span className={styles.confirmation__paragraphBold}>
              {t('confirmationInfoThird1/2')}
            </span>
            &nbsp;{t('confirmationInfoThird2/2')}
          </p>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button onPress={handleClose}>{t('confirmationButton')}</Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openBookingFUNC: () => dispatch(toggleOpenBooking()),
  showConfirmationFUNC: () => dispatch(toggleShowConfirmation()),
  openFormFUNC: () => dispatch(toggleOpenForm()),
  resetDataFUNC: () => dispatch(toggleResetData()),
});

const mapStateToProps = (state) => ({
  arrivalDate: state.Form.arrivalDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
