import React from 'react';

import styles from './mainForTerms.module.scss';

const TermsOfService = () => (
  <>
    <header>
      <h2 className={styles.box__header}>
        REGULAMIN ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ ZA POŚREDNICTWEM PORTALU „DEMO”
      </h2>
    </header>
    <div className={styles.box}>
      <h3>
        <strong>Postanowienia Ogólne</strong>
        <p>
          <strong>&sect;1</strong>
        </p>
      </h3>
      <ol>
        <li>
          Niniejszy dokument określa w szczeg&oacute;lności zasady świadczenia i korzystania przez
          Użytkownik&oacute;w z usług elektronicznych oferowanych za pośrednictwem Portalu{' '}
          <strong>DEMO</strong> przez Usługodawcę, w tym proces dokonywania Rezerwacji oraz warunki
          Um&oacute;w Usługi <strong>DEMO</strong>.
        </li>
        Niniejszy dokument określa prawa i obowiązki Usługodawcy oraz Użytkownik&oacute;w i jest
        regulaminem, o kt&oacute;rym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu
        usług drogą elektroniczną (Dz.U. z 2002 r. Nr 144, poz. 1204 z p&oacute;źn. zm.).
        <li>
          Usługodawca świadczy usługi drogą elektroniczną za pośrednictwem Portalu{' '}
          <strong>DEMO</strong> w zakresie określonym w &sect;4 niniejszego Regulaminu.
        </li>
        <li>
          Niniejszy Regulamin jest udostępniany nieodpłatnie na Stronie internetowej Usługodawcy,
          funkcjonującej w domenie (pod adresem) www.lemir24.pl, w ramach kt&oacute;rej prowadzony
          jest r&oacute;wnież Portal <strong>DEMO</strong>.<br />
          Niniejszy Regulamin udostępniany jest w formie umożliwiającej jego pozyskanie,
          odtwarzanie, utrwalanie i wydrukowanie. Użytkownik/ Reprezentant Użytkownika ma możliwość
          zapoznania się z treścią Regulaminu w każdym czasie za pomocą odsyłacza (linku)
          zamieszczonego pod adresem w/w Strony internetowej, zakładka &bdquo;Regulamin{' '}
          <strong>DEMO</strong>&rdquo;.
        </li>
        <li>
          Rozpoczęcie korzystania z usług elektronicznych oferowanych w ramach Portalu{' '}
          <strong>DEMO</strong> jest r&oacute;wnoznaczne z akceptacją warunk&oacute;w niniejszego
          Regulaminu i tym samym z zawarciem z Usługodawcą umowy o świadczenie usług drogą
          elektroniczną.
        </li>
        <li>
          Usługodawca nie pobiera żadnych opłat za komunikację za pośrednictwem Platformy{' '}
          <strong>DEMO</strong> i świadczone w jej ramach usługi elektroniczne lub w związku z
          wykorzystaniem środk&oacute;w porozumiewania się na odległość. Użytkownik/Reprezentant
          Użytkownika ponosi te koszty w wysokości wynikającej z odrębnej umowy zawartej pomiędzy
          Użytkownikiem /Reprezentantem Użytkownika i osobą trzecią świadczącą na rzecz Użytkownika
          /Reprezentanta Użytkownika usługi umożliwiające porozumiewanie się na odległość.
        </li>
        <li>
          <strong>Użytkownik zobowiązany jest do przestrzegania postanowień Regulaminu</strong>.
        </li>
      </ol>
    </div>
  </>
);

export default TermsOfService;
