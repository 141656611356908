import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleOpenBooking } from 'redux/Actions/ui-actions';

import styles from './KiaView.module.scss';

import BackgroundImage from '../../assets/0_kia.jpg';
import HeaderNavigationKia from '../../components/Navigation/header-navigationKia';
import ModalStartKia from '../../components/Modal/modal-startKia';
import Booking from '../../components/Booking/booking';
import DescriptionKia from '../../components/Description/descriptionKia';
import Map from '../../components/Map/map';
import Footer from '../../components/Footer/footer';
import Regulations from '../../components/Regulations/regulations';

const KiaView = (props) => {
  const { openBooking, openBookingFUNC } = props;
  const [showRegulations, setShowRegulations] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showRodo, setShowRodo] = useState(false);

  const handleShowRegulations = () => {
    setShowRegulations(!showRegulations);
  };
  const handleShowPrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };
  const handleShowRodo = () => {
    setShowRodo(!showRodo);
  };
  const handleCloseBoxRegulation = () => {
    setShowRegulations(false);
    setShowPrivacy(false);
    setShowRodo(false);
  };

  return (
    <>
      <div>
        <img
          className={openBooking ? styles.imageFixed : styles.image}
          src={BackgroundImage}
          alt="Tło"
        />
        <HeaderNavigationKia openBooking={openBookingFUNC} />
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.box}>
              {openBooking ? (
                <Booking openBooking={openBookingFUNC} />
              ) : (
                <ModalStartKia openBooking={openBookingFUNC} />
              )}
            </div>
          </div>
        </div>
      </div>
      {!openBooking && (
        <>
          <div className={styles.container}>
            <DescriptionKia />
            <Map />
          </div>
          {showRegulations && (
            <Regulations
              handleShowRegulations={handleShowRegulations}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showPrivacy && (
            <Regulations
              handleShowPrivacy={handleShowPrivacy}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          {showRodo && (
            <Regulations
              handleShowRodo={handleShowRodo}
              handleCloseBoxRegulation={handleCloseBoxRegulation}
            />
          )}
          <Footer
            handleShowRegulations={handleShowRegulations}
            handleShowPrivacy={handleShowPrivacy}
            handleShowRodo={handleShowRodo}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openBookingFUNC: () => dispatch(toggleOpenBooking()),
});

const mapStateToProps = (state) => ({
  openBooking: state.UI.openBooking,
});

export default connect(mapStateToProps, mapDispatchToProps)(KiaView);
