import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import GlobalStyle from 'theme/GlobalStyle';
import store from '../redux/store';
import LandingView from './LandingView/LandingView';
import KiaView from './KiaView/KiaView';
import QrView from './QrView/QrView';

const App = () => (
  <Provider store={store}>
    <GlobalStyle />
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingView />
        </Route>
        <Route path="/kia">
          <KiaView />
        </Route>
        <Route path="/qr/:code">
          <QrView />
        </Route>
      </Switch>
    </Router>
  </Provider>
);

export default App;
