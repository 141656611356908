import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';
import BergRegions from '../../assets/logo/berg_logo.jpg';
import BP from '../../assets/logo/bp_logo.jpg';
// import Logo from '../../assets/logo/logo_toyota_ciemne.png';

const Footer = ({ handleShowRegulations, handleShowPrivacy, handleShowRodo }) => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <section className={styles.footer__wrapper}>
        <header className={styles.logo}>
          <div>
            <img className={styles.logo__brand} src={BergRegions} alt="Logo" />
            <p className={styles.footer__title}>{t('footerTitle')}</p>
            {/* <p className={styles.footer__title}>{t('footerTitleSecond')}</p> */}
            <p className={styles.footer__paragraph}>{t('footerParagraph')}</p>
            <p className={styles.footer__paragraph}>{t('footerParagraphSecond')}</p>
          </div>
          <div className={styles.media}>
            <p className={styles.footer__title}>Powered by</p>
            <a
              className={styles.media__link}
              href="http://bergregions.pl/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={BergRegions} alt="Logo BergRegions" title="BergRegions" />
            </a>
            <a
              className={styles.media__link}
              href="http://bpflow.pl/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={BP} alt="Logo BPFlow" title="BPFlow" />
            </a>
          </div>
        </header>

        <div className={styles.link}>
          <button type="button" className={styles.link__item} onClick={handleShowRegulations}>
            {t('footerTermFirst')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowPrivacy}>
            {t('footerTermSecond')}
          </button>
          <button type="button" className={styles.link__item} onClick={handleShowRodo}>
            {t('footerTermThird')}
          </button>
        </div>
        <p className={styles.footer__copyright}>{t('footerTermCopyright')}</p>
      </section>
    </footer>
  );
};

export default Footer;
