import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './description.module.scss';

const DescriptionKia = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={styles.description} id="about">
        <div
          className={styles.description__photo}
          style={{
            background: `url('${process.env.PUBLIC_URL}/img/kia_0.jpg')`,
            backgroundSize: 'cover',
            backgroundPositionX: -160,
          }}
        />
        <div className={styles.description__box}>
          <header>
            <h2 className={styles.description__title}>O nas</h2>
          </header>
          <h3 className={styles.description__subtitle}>BergRegions</h3>
          <p className={styles.description__paragraph}>
            Od ponad 5 lat budujemy systemy informatyczne oparte o ekrany dotykowe oraz duże ekrany
            Digital Signage. Projekty zrealizowaliśmy między innymi w: UK, Szwajcarii, Niemczech,
            Austrii, Słowenii, Słowacji oraz w Polsce.
          </p>
          <p className={styles.description__paragraph}>
            CEO <span style={{ fontWeight: 'bold' }}>Patrycja Zielińska</span> /
            patrycja@bergregions.pl / <span style={{ fontWeight: 'bold' }}>+48 666 528 797</span>
          </p>
          <h3 className={styles.description__subtitle}>evosoft</h3>
          <p className={styles.description__paragraph}>
            Od ponad 15 lat tworzymy rozwiązania dedykowane, w tym program BP Flow kierowany do
            serwisów blacharsko-lakierniczych. Jest to program inny niż wszystkie, ponieważ powstał
            w serwisie i jest rozwijany dzięki pomysłom użytkowników.
          </p>
          <p className={styles.description__paragraph}>
            CEO <span style={{ fontWeight: 'bold' }}>Bartłomiej Szabatowski</span> /
            bartlomiej.szabatowski@dtfl.pl /
            <span style={{ fontWeight: 'bold' }}>+48 601 515 930</span>
          </p>
        </div>
      </section>
      <section className={styles.descriptionOffer}>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/kia_1.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -55,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFirst')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFirst')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/kia_2.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -70,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleSecond')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphSecond')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/kia_3.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -70,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleThird')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphThird')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/kia_4.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -100,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFourth')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFourth')}
          </p>
        </div>
        <div>
          <div
            className={styles.description__photoSmall}
            style={{
              background: `url('${process.env.PUBLIC_URL}/img/kia_5.jpg')`,
              backgroundSize: 'cover',
              backgroundPositionX: -60,
            }}
          />
          <p className={styles.description__service}>{t('descriptionOfferTitleFifth')}</p>
          <p className={styles.description__serviceParagraph}>
            {t('descriptionOfferParagraphFifth')}
          </p>
        </div>
      </section>
    </>
  );
};

export default DescriptionKia;
