import React from 'react';

import styles from './mainForTerms.module.scss';

const Rodo = () => (
  <>
    <header>
      <h2 className={styles.box__header}>RODO</h2>
    </header>
    <div className={styles.box}>
      <p>
        <strong>Jak przetwarzamy Twoje dane?</strong> Obowiązek informacyjny na podstawie
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679/UE z 27.04.2016 r. w sprawie
        ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
        swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne
        rozporządzenie o ochronie danych) (Dz.Urz. UE L 119, s. 1) (dalej RODO).
      </p>
      <p>
        1) <strong>Zakres danych</strong>
        <strong>:</strong> Przetwarzamy następujące kategorie Twoich danych: dane identyfikacyjne,
        dane kontaktowe oraz korespondencyjne, dane dot. ofert;
      </p>
      <p>a jeśli jesteś naszym klientem, także dane legalnie zgromadzone na Tw&oacute;j temat;</p>
      <p>
        2) <strong>Wsp&oacute;ładministratorzy oraz cele i podstawy przetwarzania</strong>
        <strong>:</strong>
      </p>
      <p>
        a) <strong>W związku z Twoim zainteresowaniem ofertą na zakup samochodu</strong> (w tym
        zakresie podanie przez Ciebie danych jest niezbędne do podjęcia działań w celu realizacji
        przyszłej umowy):
      </p>
      <p>Kto:</p>
      <p>
        <strong>- DEMO</strong> wpisana do Rejestru Przedsiębiorc&oacute;w prowadzonego przez Sąd
        Rejonowy w Poznaniu &ndash;
      </p>
      <p>W jakim celu i na jakiej podstawie prawnej:</p>
      <p>
        - w celu podjęcia działań niezbędnych do realizacji umowy, tj. przygotowania i
        przedstawienia na Twoje żądanie oferty (podstawa z art. 6 ust 1 lit. B RODO);
      </p>
      <p>
        - w odniesieniu do kontaktu telefonicznego lub mailowego ws. żądanej oferty, traktujemy fakt
        pozostawienia przez Ciebie tych danych jako zgodę na kontakt w tym celu zgodnie z przepisami
        o marketingu elektronicznym;
      </p>
      <p>
        - w celach archiwalnych (dowodowych) będących realizacją naszego prawnie uzasadnionego
        interesu dotyczącego zabezpieczenia informacji na wypadek prawnej potrzeby wykazania
        fakt&oacute;w, ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami (art. 6
        ust. 1 lit. f RODO);
      </p>
      <p>
        - w celach analitycznych i statystycznych - lepszego doboru produkt&oacute;w i usług do
        potrzeb naszych klient&oacute;w, og&oacute;lnej optymalizacji naszych produkt&oacute;w,
        optymalizacji proces&oacute;w obsługi, budowania wiedzy o naszych klientach (w zakresie
        budowania profili indywidualnych zobacz punkt b)
      </p>
      <p>
        poniżej), analizy finansowej VGP oraz sieci dealerskiej, będącego realizacją naszego prawnie
        uzasadnionego interesu (podstawa z art. 6 ust. 1 lit. F RODO);
      </p>
      <p>
        - w celu dokonywania rozliczeń wewnętrznych między Importerem oraz Autoryzowanym Dealerem /
        Autoryzowanym Partnerem Serwisowym oraz prowadzenia audyt&oacute;w wewnętrznych,
        raportowania wewnętrznego (podstawa z art. 6 ust. 1 lit. f RODO);
      </p>
      <p>
        - w celu realizacji Twoich praw, aktualizacji Twoich danych, prowadzenia bazy
        klient&oacute;w łączącej dane z r&oacute;żnych system&oacute;w i źr&oacute;deł (podstawa z
        art. 6 ust. 1 lit. f RODO);
      </p>
      <p>
        3) <strong>Kontakt z inspektorem ochrony danych VGP</strong>
        <strong>:</strong> Możesz skontaktować się z IOD VGP pod adresem e-mail iod@vw-group.pl, a
        także pisemnie na adres
      </p>
      <p>
        4) <strong>Odbiorcy danych</strong>
        <strong>:</strong> Odbiorcami Twoich danych osobowych będą: firmy świadczące usługi na nasze
        zlecenie, kt&oacute;rym zlecimy czynności wymagające przetwarzania danych, w
        szczeg&oacute;lności w zakresie usług IT, usług marketingowych (m.in. agencje marketingowe),
        badań rynku, firmy wspierające organizację i realizację wydarzeń, Call Center, audytorzy.
        Dane możemy udostępnić sp&oacute;łkom z grupy, w tym Volkswagen AG;
      </p>
      <p>
        5) <strong>Okres przechowywania</strong>
        <strong>:</strong> Twoje dane osobowe w związku z zainteresowaniem i przygotowaniem dla
        Ciebie oferty będziemy przetwarzać przez okres niezbędny do obsługi Twojego zapytania o
        ofertę (nie dłużej niż 6 miesięcy, jeśli stracisz zainteresowanie ofertą i nie dojdzie do
        zawarcia umowy). W odniesieniu do pozostałych cel&oacute;w opartych na naszym uzasadnionym
        interesie przez okres ważności tych cel&oacute;w albo do czasu wniesienia przez Ciebie
        sprzeciwu, w każdym przypadku nie dłużej niż 5 lat;
      </p>
      <p>
        6) <strong>Pouczenie o prawach</strong>
        <strong>:</strong> Masz prawo dostępu do treści swoich danych oraz prawo ich sprostowania,
        usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia
        zgody na kontakt telefoniczny lub elektroniczny w dowolnym momencie poprzez formularz
        umieszczony na
      </p>
      <p>
        stronie internetowej www.demo.com.pl lub u Autoryzowanego Dealera lub Autoryzowanego
        Partnera Serwisowego marki DEMO cofnięcie zgody pozostaje bez wpływu na zgodność z prawem
        przetwarzania Twoich danych, kt&oacute;rego dokonano na podstawie zgody przed jej
        cofnięciem. Możesz zrealizować swoje prawa m.in. przez kontakt na adres e-mail:
        email@demo.pl. Możesz skontaktować się także z pozostałymi Administratorami bezpośrednio;
      </p>
      <p>
        7) <strong>Prawo do wniesienia sprzeciwu</strong>
        <strong>:</strong> Masz prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych w
        związku z celami opartymi na podstawie naszego prawnie uzasadnionego interesu, opisanego
        powyżej (w takim przypadku przestaniemy przetwarzać Twoje dane w tych celach, chyba że
        będziemy w stanie wykazać, że w stosunku do tych danych istnieją dla nas ważne prawnie
        uzasadnione podstawy, kt&oacute;re są nadrzędne wobec Twoich interes&oacute;w, praw i
        wolności, lub dane będą nam niezbędne do ewentualnego ustalenia, dochodzenia lub obrony
        roszczeń). Masz prawo do sprzeciwu wobec marketingu bezpośredniego, w tym profilowania;
      </p>
      <p>
        8) Prawo do skargi: Masz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
        jeśli uznasz, że przetwarzanie danych osobowych narusza przepisy RODO;
      </p>
      <p>
        9) Źr&oacute;dło pochodzenia danych: Dane udostępnianie przez Ciebie, dane zebrane i
        udostępnione przez lub w imieniu Administrator&oacute;w (dane korespondencyjne jak wyżej),
        dane na podstawie dokument&oacute;w, dane pochodzące ze stron internetowych, w tym
        plik&oacute;w cookies; w odniesieniu do podmiot&oacute;w prowadzących działalność
        gospodarczą z także z publicznych rejestr&oacute;w CEIDG oraz KRS.
      </p>
    </div>
  </>
);

export default Rodo;
