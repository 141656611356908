import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import styles from './header-navigation.module.scss';
import Navigation from './components/navigation';
import Flag from '../../assets/flag-poland.svg';
import Logo from '../../assets/logo/react_logo.svg';

const HeaderNavigation = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className={styles.header}>
      <div className={styles.header__menu}>
        <NavLink to="/">
          <img className={styles.header__logo} src={Logo} alt="Logo" />
        </NavLink>
        <Navigation />
        <div className={styles.country}>
          <button
            className={styles.country__button}
            type="button"
            onClick={() => changeLanguage('pl')}
          >
            <img className={styles.country__flag} src={Flag} alt="Flaga wybranego języka" />
          </button>
          <p className={styles.country__name}>PL</p>
        </div>
      </div>
    </header>
  );
};

export default HeaderNavigation;
